import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_u0Vr1vkWtR from "/vercel/path0/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/vercel/path0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import chunk_reload_client_qo5j67gldm from "/vercel/path0/plugins/chunk-reload.client.js";
import loadUser_LsqsWAQztf from "/vercel/path0/plugins/loadUser.ts";
import resize_client_JYetQn3Ixt from "/vercel/path0/plugins/resize.client.js";
import tooltip_07WqZjDZUO from "/vercel/path0/plugins/tooltip.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  pwa_client_Eorgdr12jA,
  plugin_u0Vr1vkWtR,
  plugin_w2VlvAEcpa,
  nuxt_plugin_6wEQMY3tee,
  chunk_reload_client_qo5j67gldm,
  loadUser_LsqsWAQztf,
  resize_client_JYetQn3Ixt,
  tooltip_07WqZjDZUO
]